import React from 'react';


const Social = () => {
  return (
    <div className="home__social"
    
    >
        <a href="https://www.linkedin.com/in/sahanerandikaweerakkody" className="home__social-icon" target="_blank">
            <i class="uil uil-linkedin"></i>
        </a>
        <a href="https://github.com/iamsahan" className="home__social-icon" target="_blank">
            <i class="uil uil-github"></i>
        </a>
        <a href="" className="home__social-icon" target="_blank">
            <i class="uil uil-twitter"></i>
        </a>
    </div>
  )
}

export default Social